<template>
  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.950708 0.528514C0.690177 0.788864 0.690177 1.21097 0.950708 1.47132L4.95348 5.47132C5.21401 5.73167 5.63642 5.73167 5.89695 5.47132L9.89973 1.47132C10.1603 1.21097 10.1603 0.788864 9.89973 0.528515C9.6392 0.268165 9.21679 0.268165 8.95626 0.528514L5.42522 4.05711L1.89417 0.528514C1.63364 0.268165 1.21124 0.268165 0.950708 0.528514Z"
      fill="#323232"
    />
  </svg>
</template>
